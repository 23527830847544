import { AxiosPromise } from 'axios';
import axiosInstance from '@/helpers/axios.config';

class CategoryService {
  protected get baseUrl(): string {
    return `${process.env.VUE_APP_API_URL}/cleo/category`;
    // return `${process.env.VUE_APP_API_URL}/business/categories`;
  }

  async getAll(): Promise<AxiosPromise> {
    // return process.env.VUE_APP_CATEGORIES.split(', ').sort();
    return axiosInstance.get(this.baseUrl);
  }
}

export default new CategoryService();
