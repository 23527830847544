import { AxiosPromise } from 'axios';
import axiosInstance from '@/helpers/axios.config';

class BusinessService {
  protected get baseUrl(): string {
    return `${process.env.VUE_APP_API_URL}/business`;
  }

  getBySiret(siret: string): Promise<AxiosPromise> {
    const url = `${this.baseUrl}/siret/${siret}`;
    return axiosInstance.get(url);
  }

  getByRna(rna: string): Promise<AxiosPromise> {
    return axiosInstance.get(`${this.baseUrl}/rna/${rna}`);
  }
}

export default new BusinessService();
